import Vue from "vue";
import Vuetify from "vuetify";

// VCurrencyField
import VCurrencyField from "v-currency-field";
Vue.use(VCurrencyField, {
    locale: "kr-KR",
    decimalLength: 0,
    autoDecimalMode: false,
    defaultValue: 0,
    min: null,
    max: null,
    valueAsInteger: false,
    allowNegative: true,
});

Vue.use(Vuetify);

export const basicTheme = {
    themes: {
        light: {
            primary: {
                lighten5: "#E0EEF7",
                lighten4: "#B3D4EB",
                lighten3: "#80B8DE",
                lighten2: "#4D9CD0",
                lighten1: "#2686C6",
                base: "#0071BC",
                darken1: "#0069B6",
                darken2: "#005EAD",
                darken3: "#0054A5",
                darken4: "#1FA6EC",
                accent1: "#C2D8FF",
                accent2: "#8FB7FF",
                accent3: "#5C97FF",
                accent4: "#4287FF",
            },
            secondary: {
                lighten5: "#E9F8FE",
                lighten4: "#C7EEFC",
                lighten3: "#A2E3FA",
                lighten2: "#7CD8F7",
                lighten1: "#60CFF6",
                base: "#44C7F4",
                darken1: "#3EC1F3",
                darken2: "#35BAF1",
                darken3: "#2DB3EF",
                darken4: "#1FA6EC",
                accent1: "#EFF9FF",
                accent2: "#BCE7FF",
                accent3: "#A2DDFF",
            },
            // accent: {
            //     lighten5: "#E5ECFF",
            //     lighten4: "#BFD0FF",
            //     lighten3: "#94B1FF",
            //     lighten2: "#6991FF",
            //     lighten1: "#497AFF",
            //     base: "#2962FF",
            //     darken1: "#245AFF",
            //     darken2: "#1F50FF",
            //     darken3: "#1946FF",
            //     darken4: "#0F34FF",
            // },
            grey: {
                lighten5: "#F7F7F7",
                lighten4: "#ECECEC",
                lighten3: "#DDD",
                lighten2: "#AAA",
                lighten1: "#999",
                base: "#666",
                darken1: "#555",
                darken2: "#444",
                darken3: "#333",
                darken4: "#111",
            },
            success: "#4CAF50",
            warning: "#FFC107",
            error: "#FF5252",
            info: "#2196F3",
            anchor: "inherit",
        },
    },
    options: { customProperties: true },
};

export const consoleTheme = {
    themes: {
        light: {
            primary: "#00C73C",
            secondary: "#445163",
            accent: "#00C73C",
            info: "#2D3539",
            content: "#EDF0F5",
            anchor: "#333",
        },
    },
};

/**
 *
 * @param {(basicTheme|consoleTheme)} theme
 * @returns
 */
export default function (theme) {
    return new Vuetify({
        lang: {
            locales: { ko: require("vuetify/lib/locale/ko") },
            current: "ko",
        },
        breakpoint: {
            mobileBreakpoint: "md",
            thresholds: {
                xs: 576,
                sm: 768,
                md: 1024,
                lg: 1200,
            },
            scrollBarWidth: 0,
        },
        theme: theme || basicTheme,
    });
}
