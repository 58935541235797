import axios from "@/plugins/axios";
import CryptoAES from '@/plugins/crypto-aes';

export default {
    getisRealToken(refreshToken){
        var bearerToken = `Bearer ${refreshToken}`;

        return axios.get(`/api/auth/isRealToken`, {
            headers: { "Authorization": bearerToken }
        }).then(result => result.data);
    },
    getUserToken(data){
        return axios.get(`/api/auth/getUserId/${data.username}`).then(result => result.data);
    },
    getRefreshToken({ username, password, otp }){
        var basicToken = "Basic " + CryptoAES.encrypt( new Buffer(`${username}:${password}:${otp}`).toString('base64') );

        return axios.get(`/api/auth/refreshToken`, {
            headers: { "Authorization": basicToken }
        }).then(result => result.data);
    },
    getAccessToken(refreshToken){
        var bearerToken = `Bearer ${refreshToken}`;

        return axios.get(`/api/auth/accessToken`, {
            headers: { "Authorization": bearerToken }
        }).then(result => result.data);
    },
    deleteAccessToken({ _user }){
        return axios.delete(`/api/auth/deleteAccessToken/${_user}`).then(result => result.data);
    }
}
