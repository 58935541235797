<template>
    <div v-if="className == 'sub-tab'" :class="className">
        <v-container>
            <template v-for="item in gnbs">
                <v-tabs v-if="item.title == sh" :key="item.title" hide-slider>
                    <v-tab v-for="child in item.children" :key="child.title" :to="child.path" :class="{ 'v-tab--active': tabActive == child.title, 'v-tab--wide' : child.title === 'VR체험관(360°)' }">
                        <span class="text">{{ child.title }}</span>
                    </v-tab>
                </v-tabs>
            </template>
        </v-container>
    </div>
    <ul v-else-if="className == 'gnb'" :class="className">
        <template v-for="item in gnbs">
            <li v-if="!item.children" :class="className + '__li'" :key="item.title">
                <router-link :to="item.path" :class="className + '__link'"
                    ><h2>{{ item.title }}</h2></router-link
                >
            </li>

            <li v-else :class="className + '__li'" :key="item.title">
                <div :class="className + '__link gnb__link--toggle d-xl-none'">
                    <h2>{{ item.title }}</h2>
                </div>
                <router-link :to="item.path" :class="className + '__link gnb__link--toggle d-none d-xl-flex'"
                    ><h2>{{ item.title }}</h2></router-link
                >
                <ul :class="className + '__sub'">
                    <li v-for="child in item.children" :key="child.title">
                        <router-link :to="child.path">{{ child.title }}</router-link>
                    </li>
                </ul>
            </li>
        </template>
    </ul>
    <div v-else-if="className == 'slide-gnb'" :class="className">
        <template v-for="item in gnbs">
            <div :class="className + '__li'" :key="item.title">
                <router-link :to="item.path" :class="className + '__link'"
                    ><h2>{{ item.title }}</h2></router-link
                >
                <ul :class="className + '__sub'">
                    <li v-for="child in item.children" :key="child.title">
                        <router-link :to="child.path">{{ child.title }}</router-link>
                    </li>
                </ul>
            </div>
        </template>
        <div class="hidden-btn" tabindex="0"/>
    </div>
</template>

<script>
export default {
    props: {
        className: String,
        indexNum: String,
        sh: String,
        tabActive: String,
    },
    data: function () {
        return {
            window: {
                width: 0,
                height: 0,
            },
            gnbs: [
                {
                    title: "관람안내",
                    path: "/information/information",
                    children: [
                        {
                            title: "관람안내",
                            path: "/information/information",
                        },
                        {
                            title: "시설안내",
                            path: "/information/facility",
                        },
                        {
                            title: "리플렛",
                            path: "/information/leaflet",
                        },
                        {
                            title: "오시는 길",
                            path: "/information/directions",
                        },
                    ],
                },
                {
                    title: "전시",
                    path: "/exhibition/hall/1",
                    children: [
                        {
                            title: "VR체험관(360°)",
                            path: "/exhibition/online",
                        },
                        {
                            title: "상설전시",
                            path: "/exhibition/hall/1",
                        },
                        {
                            title: "특별전시",
                            path: "/exhibition/special",
                        },
                    ],
                },
                {
                    title: "교육 · 행사",
                    path: "/program/education",
                    children: [
                        {
                            title: "교육 안내 및 소개",
                            path: "/program/education",
                        },
                        {
                            title: "포토 갤러리",
                            path: "/program/gallery",
                        },
                    ],
                },
                {
                    title: "소장품",
                    path: "/collection/major",
                    children: [
                        {
                            title: "주요 소장품 소개",
                            path: "/collection/major",
                        },
                        {
                            title: "열람·복제 안내",
                            path: "/collection/reading",
                        },
                        {
                            title: "기증·기탁 안내",
                            path: "/collection/donation",
                        },
                    ],
                },
                {
                    title: "소개",
                    path: "/introduction/introduction",
                    children: [
                        {
                            title: "역사관 소개",
                            path: "/introduction/introduction",
                        },
                        {
                            title: "공지사항",
                            path: "/introduction/notice",
                        },
                        {
                            title: "언론보도",
                            path: "/introduction/coverage",
                        },
                        {
                            title: "도서",
                            path: "/introduction/book",
                        },
                    ],
                },
                // {
                //     title : "가이드",
                //     path : "/guide",
                //     children : [
                //         {
                //             title : "가이드",
                //             path : "/guide",
                //         },
                //         {
                //             title : "서브메뉴명1",
                //             path : "/",
                //         },
                //         {
                //             title : "서브메뉴명2",
                //             path : "/",
                //         },
                //         {
                //             title : "서브메뉴명3",
                //             path : "/",
                //         },
                //         {
                //             title : "서브메뉴명4",
                //             path : "/",
                //         },
                //     ]
                // },
                // {
                //     title : "게시판",
                //     path : "/center/notice",
                //     children : [
                //         {
                //             title : "공지사항",
                //             path : "/center/notice",
                //         },
                //         {
                //             title : "FAQ",
                //             path : "/center/faqs",
                //         },
                //     ]
                // },
                // {
                //     title : "폼메일",
                //     path : "/inquiry",
                // },
                // {
                //     title : "약관",
                //     path : "/privacy-policy",
                //     children : [
                //         {
                //             title : "서브메뉴명1",
                //             path : "/",
                //         },
                //         {
                //             title : "서브메뉴명2",
                //             path : "/",
                //         },
                //         {
                //             title : "서브메뉴명3",
                //             path : "/",
                //         },
                //         {
                //             title : "서브메뉴명4",
                //             path : "/",
                //         },
                //     ]
                // },
            ],
        };
    },
    computed: {
        isDesktop: function () {
            return this.window.width >= 1200;
        },
        isMobile: function () {
            return this.window.width < 1200;
        },
    },
    created: function () {
        window.addEventListener("resize", this.handleResize);
        this.handleResize();
    },
    destroyed() {
        window.removeEventListener("resize", this.handleResize);
    },
    mounted: function () {},
    methods: {
        handleResize: function () {
            this.window.width = window.innerWidth;
            this.window.height = window.innerHeight;
        },
    },
};
</script>