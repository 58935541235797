<template>
    <btn-hamburger @click="$emit('click')" tabindex=“0”><slot /></btn-hamburger>
</template>

<script>
import BtnHamburger from "@/components/publish/styles/buttons/btn-hamburger.vue";
// import UBtnHamburgerConcave from "@/components/publish/styles/buttons/u-btn-hamburger-concave.vue";

export default {
    props: {},
    components: {
        BtnHamburger,
        // UBtnHamburgerConcave,
    },
    data: () => {
        return {};
    },
    mounted() {},
    methods: {},
};
</script>

<style lang="scss" scoped></style>
