var render = function render(){
  var _vm=this;
  var _h=_vm.$createElement;
  var _c=_vm._self._c||_h;

  return _c('v-btn', _vm._b({
    directives: [{
      name: "scroll",
      rawName: "v-scroll",
      value: _vm.onScroll,
      expression: "onScroll"
    }],
    staticClass: "btn-top-primary rounded",
    attrs: {
      "fixed": "",
      "x-large": "",
      "icon": "",
      "outlined": "",
      "color": "grey lighten-3"
    },
    on: {
      "click": function ($event) {
        return _vm.$vuetify.goTo(_vm.target, _vm.options);
      }
    }
  }, 'v-btn', Object.assign({
    color: _vm.color
  }, _vm.$attrs), false), [_c('span', {
    staticClass: "grey--text text--darken-4"
  }, [_vm._v(" TOP ")])]);

}
var staticRenderFns = []

export { render, staticRenderFns }