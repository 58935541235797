var render = function render(){
  var _vm=this;
  var _h=_vm.$createElement;
  var _c=_vm._self._c||_h;

  return _vm.className == 'sub-tab' ? _c('div', {
    class: _vm.className
  }, [_c('v-container', [_vm._l(_vm.gnbs, function (item) {
    return [item.title == _vm.sh ? _c('v-tabs', {
      key: item.title,
      attrs: {
        "hide-slider": ""
      }
    }, _vm._l(item.children, function (child) {
      return _c('v-tab', {
        key: child.title,
        class: {
          'v-tab--active': _vm.tabActive == child.title,
          'v-tab--wide': child.title === 'VR체험관(360°)'
        },
        attrs: {
          "to": child.path
        }
      }, [_c('span', {
        staticClass: "text"
      }, [_vm._v(_vm._s(child.title))])]);
    }), 1) : _vm._e()];
  })], 2)], 1) : _vm.className == 'gnb' ? _c('ul', {
    class: _vm.className
  }, [_vm._l(_vm.gnbs, function (item) {
    return [!item.children ? _c('li', {
      key: item.title,
      class: _vm.className + '__li'
    }, [_c('router-link', {
      class: _vm.className + '__link',
      attrs: {
        "to": item.path
      }
    }, [_c('h2', [_vm._v(_vm._s(item.title))])])], 1) : _c('li', {
      key: item.title,
      class: _vm.className + '__li'
    }, [_c('div', {
      class: _vm.className + '__link gnb__link--toggle d-xl-none'
    }, [_c('h2', [_vm._v(_vm._s(item.title))])]), _c('router-link', {
      class: _vm.className + '__link gnb__link--toggle d-none d-xl-flex',
      attrs: {
        "to": item.path
      }
    }, [_c('h2', [_vm._v(_vm._s(item.title))])]), _c('ul', {
      class: _vm.className + '__sub'
    }, _vm._l(item.children, function (child) {
      return _c('li', {
        key: child.title
      }, [_c('router-link', {
        attrs: {
          "to": child.path
        }
      }, [_vm._v(_vm._s(child.title))])], 1);
    }), 0)], 1)];
  })], 2) : _vm.className == 'slide-gnb' ? _c('div', {
    class: _vm.className
  }, [_vm._l(_vm.gnbs, function (item) {
    return [_c('div', {
      key: item.title,
      class: _vm.className + '__li'
    }, [_c('router-link', {
      class: _vm.className + '__link',
      attrs: {
        "to": item.path
      }
    }, [_c('h2', [_vm._v(_vm._s(item.title))])]), _c('ul', {
      class: _vm.className + '__sub'
    }, _vm._l(item.children, function (child) {
      return _c('li', {
        key: child.title
      }, [_c('router-link', {
        attrs: {
          "to": child.path
        }
      }, [_vm._v(_vm._s(child.title))])], 1);
    }), 0)], 1)];
  }), _c('div', {
    staticClass: "hidden-btn",
    attrs: {
      "tabindex": "0"
    }
  })], 2) : _vm._e();

}
var staticRenderFns = []

export { render, staticRenderFns }