<template>
    <router-link :to="to" class="d-flex justify-space-between px-24 py-40">
        <h3 class="tit tit--sm">{{ tit }}</h3>
        <icon-arrow-primary direction="right" />
    </router-link>
</template>

<script>
import IconArrowPrimary from "@/components/publish/parents/icons/icon-arrow-primary.vue";
export default {
    components: {
        IconArrowPrimary,
    },
    props: {
        to: { type: String, default: "" },
        tit: { type: String, default: "" },
    },
    data: () => {
        return {};
    },
};
</script>

<style lang="scss" scoped>
a {
    color: #fff !important;
    transition: all 0.3s;
    border-bottom: 1px solid #fff;
    &:focus,
    &:hover {
        background-color: #fff;
        color: var(--v-primary-base) !important;
    }
}
</style>
