var render = function render(){
  var _vm=this;
  var _h=_vm.$createElement;
  var _c=_vm._self._c||_h;

  return _c('footer', {
    staticClass: "footer"
  }, [_c('div', {
    staticClass: "footer__top"
  }, [_c('v-container', [_c('v-row', {
    staticClass: "align-center py-20",
    attrs: {
      "no-gutters": ""
    }
  }, [_c('v-col', {
    attrs: {
      "cols": "12",
      "md": ""
    }
  }, [_c('v-row', {
    staticClass: "align-center",
    attrs: {
      "no-gutters": ""
    }
  }, [_c('v-col', {
    attrs: {
      "align": "center",
      "cols": "auto"
    }
  }, [_c('router-link', {
    staticClass: "d-flex jusify-ceter align-center h-40px",
    attrs: {
      "to": "/introduction/introduction"
    }
  }, [_c('span', {
    staticClass: "page-text page-text--xs white--text"
  }, [_vm._v("역사관소개")])])], 1), _c('v-col', {
    staticClass: "pa-0",
    attrs: {
      "cols": "auto"
    }
  }, [_c('span', {
    staticClass: "vr mx-10 mx-md-24"
  })]), _c('v-col', {
    attrs: {
      "align": "center",
      "cols": "auto"
    }
  }, [_c('router-link', {
    staticClass: "d-flex jusify-ceter align-center h-40px",
    attrs: {
      "to": "/information/directions"
    }
  }, [_c('span', {
    staticClass: "page-text page-text--xs white--text"
  }, [_vm._v("찾아오시는 길")])])], 1), _c('v-col', {
    staticClass: "pa-0",
    attrs: {
      "cols": "auto"
    }
  }, [_c('span', {
    staticClass: "vr mx-10 mx-md-24"
  })]), _c('v-col', {
    attrs: {
      "align": "center",
      "cols": "auto"
    }
  }, [_c('router-link', {
    staticClass: "d-flex jusify-ceter align-center h-40px",
    attrs: {
      "to": "/information/information"
    }
  }, [_c('span', {
    staticClass: "page-text page-text--xs white--text"
  }, [_vm._v("이용안내")])])], 1)], 1)], 1), _c('v-col', {
    attrs: {
      "cols": "auto"
    }
  }, [_c('v-btn', {
    staticClass: "family-btn w-180px h-40px white--text",
    attrs: {
      "color": "white",
      "outlined": "",
      "dark": ""
    },
    on: {
      "click": _vm.toggleOn
    }
  }, [_c('div', {
    staticClass: "txt-wrap"
  }, [_c('p', [_vm._v("Family Site")]), _c('span', [_c('icon-arrow-quarternary', {
    attrs: {
      "direction": "right"
    }
  })], 1)]), _c('div', {
    staticClass: "family-btn__li"
  }, [_c('v-row', {
    staticClass: "row--xs"
  }, [_c('v-col', {
    attrs: {
      "cols": "12"
    }
  }, [_c('p', {
    staticClass: "page-text page-text--sm primary--text"
  }, [_vm._v("새마을금고 중앙회 사이트")])]), _c('v-col', {
    attrs: {
      "cols": "12"
    }
  }, [_c('a', {
    attrs: {
      "href": "https://www.kfcc.co.kr/",
      "target": "_blank"
    }
  }, [_vm._v("새마을금고")])]), _c('v-col', {
    attrs: {
      "cols": "12"
    }
  }, [_c('a', {
    attrs: {
      "href": "https://www.kfcc.co.kr/edu/main.do",
      "target": "_blank"
    }
  }, [_vm._v("MG인재개발원")])]), _c('v-col', {
    attrs: {
      "cols": "12"
    }
  }, [_c('a', {
    attrs: {
      "href": "https://mgti.kfcc.co.kr/main.do",
      "target": "_blank"
    }
  }, [_vm._v("제주연수원")])]), _c('v-col', {
    attrs: {
      "cols": "12"
    }
  }, [_c('a', {
    attrs: {
      "href": "https://www.kccwf.or.kr/home/comm/comm0001m01/mainPage.html",
      "target": "_blank"
    }
  }, [_vm._v("새마을금고 복지회")])]), _c('v-col', {
    attrs: {
      "cols": "12"
    }
  }, [_c('a', {
    attrs: {
      "href": "https://www.kfcc.co.kr/sports/main.do",
      "target": "_blank"
    }
  }, [_vm._v("MG새마을금고 배드민턴단")])]), _c('v-col', {
    attrs: {
      "cols": "12"
    }
  }, [_c('a', {
    attrs: {
      "href": "http://www.kfccf.or.kr/main/main.do",
      "target": "_blank"
    }
  }, [_vm._v("MG새마을금고"), _c('br'), _vm._v("지역희망나눔재단")])]), _c('v-col', {
    attrs: {
      "cols": "12"
    }
  }, [_c('a', {
    attrs: {
      "href": "https://www.mginfo.co.kr/",
      "target": "_blank"
    }
  }, [_vm._v("MG신용정보")])]), _c('v-col', {
    attrs: {
      "cols": "12"
    }
  }, [_c('a', {
    attrs: {
      "href": "https://mgasset.co.kr/main/index.asp",
      "target": "_blank"
    }
  }, [_vm._v("MG자산관리")])]), _c('v-col', {
    attrs: {
      "cols": "12"
    }
  }, [_c('a', {
    attrs: {
      "href": "https://www.mgdatasystem.co.kr/",
      "target": "_blank"
    }
  }, [_vm._v("MG데이터시스템")])])], 1), _c('v-divider'), _c('v-row', {
    staticClass: "row--xs"
  }, [_c('v-col', {
    attrs: {
      "cols": "12"
    }
  }, [_c('p', {
    staticClass: "page-text page-text--sm primary--text"
  }, [_vm._v("금융사이트")])]), _c('v-col', {
    attrs: {
      "cols": "12"
    }
  }, [_c('a', {
    attrs: {
      "href": "https://ibs.kfcc.co.kr/ib20/mnu/MCT0000000000344",
      "target": "_blank"
    }
  }, [_vm._v("개인 인터넷뱅킹")])]), _c('v-col', {
    attrs: {
      "cols": "12"
    }
  }, [_c('a', {
    attrs: {
      "href": "https://biz.kfcc.co.kr/ib20/mnu/CIB000000000018",
      "target": "_blank"
    }
  }, [_vm._v("기업 인터넷뱅킹")])]), _c('v-col', {
    attrs: {
      "cols": "12"
    }
  }, [_c('a', {
    attrs: {
      "href": "https://mgcheck.kfcc.co.kr/cmn/main/mainView.do",
      "target": "_blank"
    }
  }, [_vm._v("MG체크카드")])]), _c('v-col', {
    attrs: {
      "cols": "12"
    }
  }, [_c('a', {
    attrs: {
      "href": "https://insu.kfcc.co.kr/main.do",
      "target": "_blank"
    }
  }, [_vm._v("새마을금고 공제")])])], 1)], 1)])], 1)], 1)], 1)], 1), _c('v-divider'), _c('div', {
    staticClass: "footer__bottom py-30px py-lg-48px"
  }, [_c('v-container', {
    staticClass: "container--lg"
  }, [_c('v-row', {
    staticClass: "row",
    attrs: {
      "align": "center"
    }
  }, [_c('v-col', {
    staticClass: "mt-14px mt-md-0px",
    attrs: {
      "cols": "12",
      "md": ""
    }
  }, [_c('div', {
    staticClass: "page-text page-text--xs"
  }, [_c('p', {
    staticClass: "font-weight-medium mb-8 mb-md-12 mb-lg-16"
  }, [_vm._v("MG새마을금고역사관")]), _c('ul', {
    staticClass: "d-lg-flex ma-n8"
  }, _vm._l(_vm.info, function (item) {
    return _c('li', {
      key: item.title,
      staticClass: "pa-8"
    }, [_c('p', [_vm._v(" " + _vm._s(item.title) + " "), _c('span', {
      staticClass: "grey--text text--lighten-1"
    }, [_vm._v(_vm._s(item.info))])])]);
  }), 0), _c('p', {
    staticClass: "grey--text text--lighten-1 mt-8 mt-md-12 mt-lg-16"
  }, [_vm._v("Copyright 2022. COMPANY CO. All Rights Reserved.")])])]), _c('v-col', {
    attrs: {
      "cols": "12",
      "md": "auto"
    }
  }, [_c('v-img', {
    attrs: {
      "max-width": "234",
      "src": "/images/logo-dark.svg",
      "contain": "",
      "alt": "MG새마을금고역사관"
    }
  })], 1)], 1)], 1)], 1)], 1);

}
var staticRenderFns = []

export { render, staticRenderFns }