var render = function render(){
  var _vm=this;
  var _h=_vm.$createElement;
  var _c=_vm._self._c||_h;

  return _c('v-card', {
    staticClass: "main-circle-card",
    attrs: {
      "color": _vm.color,
      "outlined": ""
    }
  }, [_c('router-link', {
    attrs: {
      "to": _vm.to
    }
  }, [_c('div', {
    attrs: {
      "align": "center"
    }
  }, [_c('v-img', {
    staticClass: "w-70 w-lg-100 ma-auto mb-10 mb-md-14 mb-md-20",
    attrs: {
      "eager": "",
      "src": _vm.icon,
      "max-width": "80",
      "alt": ""
    }
  }), _c('h3', {
    staticClass: "tit tit--sm"
  }, [_vm._v(_vm._s(_vm.tit))])], 1)])], 1);

}
var staticRenderFns = []

export { render, staticRenderFns }