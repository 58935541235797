var render = function render(){
  var _vm=this;
  var _h=_vm.$createElement;
  var _c=_vm._self._c||_h;

  return _c('client-page', {
    staticClass: "app--main"
  }, [_c('main-visual'), _c('main-section', {
    staticClass: "main-section--s-guidance pa-0"
  }, [_c('div', {
    staticClass: "h-240px h-lg-120px d-flex align-center"
  }, [_c('v-row', [_c('v-col', {
    attrs: {
      "cols": "6",
      "md": ""
    }
  }, [_c('v-row', {
    staticClass: "row--lg"
  }, [_c('v-col', {
    attrs: {
      "cols": "12",
      "lg": "auto"
    }
  }, [_c('div', {
    staticClass: "tit-wrap tit-wrap--xs"
  }, [_c('h3', {
    staticClass: "tit tit--sm"
  }, [_vm._v("관람시간")])]), _c('p', {
    staticClass: "page-text page-text--sm"
  }, [_vm._v("월요일 ~ 토요일 : 오전 10시 ~ 오후 6시 "), _c('span', {
    staticClass: "grey--text"
  }, [_vm._v("(입장 마감 : 오후 5시)")])])]), _c('v-col', {
    attrs: {
      "cols": "12",
      "lg": "auto"
    }
  }, [_c('div', {
    staticClass: "tit-wrap tit-wrap--xs"
  }, [_c('h3', {
    staticClass: "tit tit--sm"
  }, [_vm._v("휴관일")])]), _c('p', {
    staticClass: "page-text page-text--sm"
  }, [_vm._v("매주 일요일, 법정 공휴일, 근로자의 날")])])], 1)], 1)], 1), _c('router-link', {
    staticClass: "group-link d-flex justify-center align-center h-100 px-20 px-md-40 pr-lg-0",
    attrs: {
      "to": "/information/information#group"
    }
  }, [_c('h3', {
    staticClass: "tit tit--sm mr-12 mr-md-24 white--text"
  }, [_vm._v("단체관람 안내")]), _c('icon-arrow-primary', {
    attrs: {
      "color": "#fff",
      "direction": "right"
    }
  })], 1)], 1)]), _c('main-section', {
    staticClass: "main-section--guidance"
  }, [_c('div', {
    staticClass: "tit-wrap text-center"
  }, [_c('h2', {
    staticClass: "tit tit--lg"
  }, [_vm._v("역사관 이용안내")])]), _c('v-row', [_c('v-col', {
    attrs: {
      "cols": "6",
      "md": ""
    }
  }, [_c('main-circle-card', {
    staticClass: "main-circle-card--1",
    attrs: {
      "to": "/information/information",
      "tit": "관람안내",
      "icon": "/images/main/circle-card-01.png",
      "color": "secondary"
    }
  })], 1), _c('v-col', {
    attrs: {
      "cols": "6",
      "md": ""
    }
  }, [_c('main-circle-card', {
    staticClass: "main-circle-card--2",
    attrs: {
      "to": "/program/education",
      "tit": "교육 안내",
      "icon": "/images/main/circle-card-02.png",
      "color": "#009edb"
    }
  })], 1), _c('v-col', {
    attrs: {
      "cols": "6",
      "md": ""
    }
  }, [_c('main-circle-card', {
    staticClass: "main-circle-card--3",
    attrs: {
      "to": "/information/facility",
      "tit": "시설 안내",
      "icon": "/images/main/circle-card-03.png",
      "color": "primary"
    }
  })], 1), _c('v-col', {
    attrs: {
      "cols": "6",
      "md": ""
    }
  }, [_c('main-circle-card', {
    staticClass: "main-circle-card--4",
    attrs: {
      "to": "/information/directions",
      "tit": "오시는 길",
      "icon": "/images/main/circle-card-04.png",
      "color": "#0b386a"
    }
  })], 1)], 1)], 1), _c('section', {
    staticClass: "main-section main-section--exhibition"
  }, [_c('v-container', [_c('div', {
    staticClass: "tit-wrap"
  }, [_c('h2', {
    staticClass: "tit tit--lg white--text"
  }, [_vm._v("전시안내")])]), _c('v-row', {
    attrs: {
      "no-gutters": ""
    }
  }, [_c('v-col', {
    attrs: {
      "cols": "12",
      "sm": "5"
    }
  }, [_c('ul', {
    staticClass: "main-section--exhibition__list"
  }, [_c('li', [_c('router-link', {
    attrs: {
      "to": "/exhibition/hall/1"
    }
  }, [_c('h3', {
    staticClass: "tit tit--sm"
  }, [_vm._v("상설전시")]), _c('icon-arrow-primary', {
    attrs: {
      "direction": "right"
    }
  }), _c('v-img', {
    staticClass: "hall-bg",
    attrs: {
      "src": "/images/main/exhibition-01.jpg",
      "alt": ""
    }
  })], 1)], 1), _c('li', [_c('router-link', {
    attrs: {
      "to": "/exhibition/special"
    }
  }, [_c('h3', {
    staticClass: "tit tit--sm"
  }, [_vm._v("특별전시")]), _c('icon-arrow-primary', {
    attrs: {
      "direction": "right"
    }
  }), _c('v-img', {
    staticClass: "hall-bg",
    attrs: {
      "src": "/images/main/exhibition-02.jpg",
      "alt": ""
    }
  })], 1)], 1)])])], 1)], 1)], 1), _c('main-section', [_c('v-row', [_c('v-col', {
    attrs: {
      "cols": "12",
      "lg": "auto"
    }
  }, [_c('v-row', {
    staticClass: "row--sm flex-md-column"
  }, [_c('v-col', {
    attrs: {
      "cols": "12",
      "md": "auto"
    }
  }, [_c('v-card', {
    staticClass: "notice-card notice-card--intro",
    attrs: {
      "flat": ""
    }
  }, [_c('router-link', {
    attrs: {
      "to": "/introduction/introduction"
    }
  }, [_c('h3', {
    staticClass: "tit tit--sm white--text"
  }, [_vm._v("역사관 소개")])])], 1)], 1), _c('v-col', {
    attrs: {
      "cols": "12",
      "md": "auto"
    }
  }, [_c('v-card', {
    staticClass: "notice-card notice-card--donation border-2",
    attrs: {
      "flat": "",
      "tile": "",
      "outlined": "",
      "color": "#009EDB",
      "hover": true
    }
  }, [_c('router-link', {
    attrs: {
      "to": "/collection/donation"
    }
  }, [_c('h3', {
    staticClass: "tit tit--sm color-009EDB"
  }, [_vm._v("기증 · 기탁 안내")])])], 1)], 1)], 1)], 1), _c('v-col', {
    attrs: {
      "cols": "12",
      "lg": ""
    }
  }, [_c('div', {
    staticClass: "tit-wrap tit-wrap--xs d-flex justify-space-between align-center"
  }, [_c('h2', {
    staticClass: "tit tit--lg"
  }, [_vm._v("공지사항")]), _c('router-link', {
    staticClass: "d-flex",
    attrs: {
      "to": "/introduction/notice"
    }
  }, [_c('span', {
    staticClass: "page-text mr-4"
  }, [_vm._v("자세히보기")]), _c('icon-arrow-tertiary', {
    attrs: {
      "direction": "right"
    }
  })], 1)], 1), _c('v-divider', {
    staticClass: "pb-20 pb-md-30 pb-lg-40"
  }), _c('v-row', {
    staticClass: "row--xs"
  }, _vm._l(_vm.notifications, function (notification, index) {
    return _c('v-col', {
      key: index,
      attrs: {
        "cols": "12"
      }
    }, [_c('main-notice-link', {
      attrs: {
        "notification": notification
      }
    })], 1);
  }), 1)], 1)], 1)], 1), _c('popup-layer')], 1);

}
var staticRenderFns = []

export { render, staticRenderFns }