<template>
    <footer class="footer">
        <div class="footer__top">
            <v-container>
                <v-row no-gutters class="align-center py-20">
                    <v-col cols="12" md="">
                        <v-row no-gutters class="align-center">
                            <v-col align="center" cols="auto">
                                <router-link to="/introduction/introduction" class="d-flex jusify-ceter align-center h-40px">
                                    <span class="page-text page-text--xs white--text">역사관소개</span>
                                </router-link>
                            </v-col>
                            <v-col cols="auto" class="pa-0">
                                <span class="vr mx-10 mx-md-24" />
                            </v-col>
                            <v-col align="center" cols="auto">
                                <router-link to="/information/directions" class="d-flex jusify-ceter align-center h-40px">
                                    <span class="page-text page-text--xs white--text">찾아오시는 길</span>
                                </router-link>
                            </v-col>
                            <v-col cols="auto" class="pa-0">
                                <span class="vr mx-10 mx-md-24" />
                            </v-col>
                            <v-col align="center" cols="auto">
                                <router-link to="/information/information" class="d-flex jusify-ceter align-center h-40px">
                                    <span class="page-text page-text--xs white--text">이용안내</span>
                                </router-link>
                            </v-col>
                        </v-row>
                    </v-col>
                    <v-col cols="auto">
                        <v-btn color="white" outlined dark class="family-btn w-180px h-40px white--text" @click="toggleOn">
                            <div class="txt-wrap">
                                <p>Family Site</p>
                                <span><icon-arrow-quarternary direction="right" /></span>
                            </div>
                            <div class="family-btn__li">
                                <v-row class="row--xs">
                                    <v-col cols="12">
                                        <p class="page-text page-text--sm primary--text">새마을금고 중앙회 사이트</p>
                                    </v-col>
                                    <v-col cols="12">
                                        <a href="https://www.kfcc.co.kr/" target="_blank">새마을금고</a>
                                    </v-col>
                                    <v-col cols="12">
                                        <a href="https://www.kfcc.co.kr/edu/main.do" target="_blank">MG인재개발원</a>
                                    </v-col>
                                    <v-col cols="12">
                                        <a href="https://mgti.kfcc.co.kr/main.do" target="_blank">제주연수원</a>
                                    </v-col>
                                    <v-col cols="12">
                                        <a href="https://www.kccwf.or.kr/home/comm/comm0001m01/mainPage.html" target="_blank">새마을금고 복지회</a>
                                    </v-col>
                                    <v-col cols="12">
                                        <a href="https://www.kfcc.co.kr/sports/main.do" target="_blank">MG새마을금고 배드민턴단</a>
                                    </v-col>
                                    <v-col cols="12">
                                        <a href="http://www.kfccf.or.kr/main/main.do" target="_blank">MG새마을금고<br />지역희망나눔재단</a>
                                    </v-col>
                                    <v-col cols="12">
                                        <a href="https://www.mginfo.co.kr/" target="_blank">MG신용정보</a>
                                    </v-col>
                                    <v-col cols="12">
                                        <a href="https://mgasset.co.kr/main/index.asp" target="_blank">MG자산관리</a>
                                    </v-col>
                                    <v-col cols="12">
                                        <a href="https://www.mgdatasystem.co.kr/" target="_blank">MG데이터시스템</a>
                                    </v-col>
                                </v-row>
                                <v-divider />
                                <v-row class="row--xs">
                                    <v-col cols="12">
                                        <p class="page-text page-text--sm primary--text">금융사이트</p>
                                    </v-col>
                                    <v-col cols="12">
                                        <a href="https://ibs.kfcc.co.kr/ib20/mnu/MCT0000000000344" target="_blank">개인 인터넷뱅킹</a>
                                    </v-col>
                                    <v-col cols="12">
                                        <a href="https://biz.kfcc.co.kr/ib20/mnu/CIB000000000018" target="_blank">기업 인터넷뱅킹</a>
                                    </v-col>
                                    <v-col cols="12">
                                        <a href="https://mgcheck.kfcc.co.kr/cmn/main/mainView.do" target="_blank">MG체크카드</a>
                                    </v-col>
                                    <v-col cols="12">
                                        <a href="https://insu.kfcc.co.kr/main.do" target="_blank">새마을금고 공제</a>
                                    </v-col>
                                </v-row>
                            </div>
                        </v-btn>
                    </v-col>
                </v-row>
            </v-container>
        </div>
        <v-divider />
        <div class="footer__bottom py-30px py-lg-48px">
            <v-container class="container--lg">
                <v-row align="center" class="row">
                    <v-col cols="12" md="" class="mt-14px mt-md-0px">
                        <div class="page-text page-text--xs">
                            <p class="font-weight-medium mb-8 mb-md-12 mb-lg-16">MG새마을금고역사관</p>
                            <ul class="d-lg-flex ma-n8">
                                <li v-for="item in info" :key="item.title" class="pa-8">
                                    <p>
                                        {{ item.title }} <span class="grey--text text--lighten-1">{{ item.info }}</span>
                                    </p>
                                </li>
                            </ul>
                            <p class="grey--text text--lighten-1 mt-8 mt-md-12 mt-lg-16">Copyright 2022. COMPANY CO. All Rights Reserved.</p>
                        </div>
                    </v-col>
                    <v-col cols="12" md="auto">
                        <v-img max-width="234" src="/images/logo-dark.svg" contain alt="MG새마을금고역사관" />
                    </v-col>
                </v-row>
            </v-container>
        </div>
    </footer>
</template>

<script>
import { attrs_input } from "@/assets/variables";
import IconArrowQuarternary from "@/components/publish/parents/icons/icon-arrow-quarternary.vue";

export default {
    components: {
        IconArrowQuarternary,
    },
    data() {
        return {
            attrs_input,
            // link: [
            //     { title: "역사관 소개", link: "/" },
            //     { title: "찾아오시는 길", link: "/" },
            //     { title: "이용안내", link: "/" },
            //     { title: "개인정보처리방침", link: "/" },
            // ],
            info: [
                // { title : "회사명", info : "사이트명", },
                { title: "주소", info: "52217 경상남도 산청군 산청읍 친환경로 2742-26(지리590)" },
                { title: "대표전화", info: "055-970-0800" },
                // { title : "사업자등록번호", info : "00-0000-0000", },
                // { title : "이메일", info : "email@gmail.com", },
            ],
        };
    },
    methods: {
        toggleOn() {
            const button = document.querySelector(".family-btn");
            button.classList.toggle("on");
        },
    },
};

document.addEventListener('mouseup', function(e) {
    var familyBtn = document.querySelector(".family-btn");
    // if (!familyBtn.contains(e.target)) {
    //     familyBtn.classList.remove("on");
    // }
});
</script>

<style lang="scss" scoped>
.footer {
    background-color: var(--v-grey-darken4);
    color: #fff;
    border-top: 1px solid rgba(255, 255, 255, 0.2);
}
::v-deep {
    .v-btn.v-size--small:not(.v-btn--icon) {
        margin: 0 -12px;
    }
    .v-divider--vertical {
        align-self: center;
        vertical-align: unset;
    }
    .v-text-field--outlined > .v-input__control > .v-input__slot {
        background: transparent;
        input::placeholder,
        i {
            color: #fff !important;
        }
    }
}
.v-divider {
    border-color: rgba(255, 255, 255, 0.2) !important;
}
.vr {
    display: block;
    width: 1px;
    height: 12px;
    background: rgba(255, 255, 255, 0.2);
}
.family-btn {
    position: relative;
    .txt-wrap {
        width: 100%;
        padding: 0 16px;
        display: flex;
        justify-content: space-between;
        align-items: center;
        i {
            transform: rotate(90deg);
        }
    }
    &__li {
        position: absolute;
        bottom: 180%;
        left: 0;
        width: 100%;
        border: 1px solid var(--v-grey-lighten3);
        background-color: #fff;
        color: var(--v-grey-darken4);
        padding: 10px;
        overflow: hidden;
        visibility: hidden;
        opacity: 0;
        transition: all 0.3s;
        padding: 15px 0;
        z-index: 999;
        .v-divider {
            border-block-style: dashed;
            border-color: var(--v-grey-lighten3) !important;
            margin: 10px 0;
        }
        a {
            display: block;
            width: 100%;
            height: 100%;
            color: var(--v-grey-lighten1);
            transition: all 0.1s;
            &:hover,
            &:focus {
                color: var(--v-grey-darken4);
            }
        }
    }
}
.family-btn.on {
    i {
        transform: rotate(-90deg);
    }
    .family-btn__li {
        height: auto;
        visibility: visible;
        opacity: 1;
    }
}
@media (min-width: 576px) {
}
@media (min-width: 768px) {
}
@media (min-width: 1024px) {
}
@media (min-width: 1200px) {
}
</style>
