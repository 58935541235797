import Vue from "vue";
import VueRouter from "vue-router";
import axios from "../plugins/axios";
import { i18n } from "../plugins/vue-i18n";

import Main from "../pages/client/Main.vue";

// import { BoardRoutes } from "./board.router";
// import { QuestionRoutes } from "./question.router";

Vue.use(VueRouter);

const routes = [
    ///////////////////////////////////////////////////////////////////////////////////////////////////////////////////
    // 사용자 페이지 라우트
    ///////////////////////////////////////////////////////////////////////////////////////////////////////////////////
    {
        // Main 페이지
        path: "/",
        component: Main,
    },

    {
        // 가이드
        path: "/guide",
        component: () => import(/* webpackChunkName: "guide" */ "../pages/client/guide/guide-app.vue"),
        props: true,
    },

    // 게시판
    // {
    //     // FAQ
    //     path: "/center/faqs",
    //     component: () => import(/* webpackChunkName: "center" */ "../pages/client/center/faq.vue"),
    //     props: true,
    // },
    
    // 관람안내
    {
        // 관람안내
        path: "/information/information",
        component: () => import(/* webpackChunkName: "center" */ "../pages/client/sub/information/information.vue"),
        props: true,
    },
    {
        // 시설안내
        path: "/information/facility",
        component: () => import(/* webpackChunkName: "center" */ "../pages/client/sub/information/facility.vue"),
        props: true,
    },
    {
        // 리플렛
        path: "/information/leaflet",
        component: () => import(/* webpackChunkName: "center" */ "../pages/client/sub/information/leaflet.vue"),
        props: true,
    },
    {
        // 오시는 길
        path: "/information/directions",
        component: () => import(/* webpackChunkName: "center" */ "../pages/client/sub/information/directions.vue"),
        props: true,
    },

    // 전시
    {
        // VR체험관(360°)
        path: "/exhibition/online",
        component: () => import(/* webpackChunkName: "center" */ "../pages/client/sub/exhibition/online.vue"),
        props: true,
    },
    {
        // 상설전시
        // 제1전시관
        path: "/exhibition/hall/1",
        component: () => import(/* webpackChunkName: "center" */ "../pages/client/sub/exhibition/hall/first.vue"),
        props: true,
    },
    {
        // 제2전시관
        path: "/exhibition/hall/2",
        component: () => import(/* webpackChunkName: "center" */ "../pages/client/sub/exhibition/hall/second.vue"),
        props: true,
    },
    {
        // 제3전시관
        path: "/exhibition/hall/3",
        component: () => import(/* webpackChunkName: "center" */ "../pages/client/sub/exhibition/hall/third.vue"),
        props: true,
    },
    {
        // 특별전시
        path: "/exhibition/special",
        component: () => import(/* webpackChunkName: "center" */ "../pages/client/sub/exhibition/special.vue"),
        props: true,
    },

    // 교육 · 행사
    {
        // 교육 안내 및 소개
        path: "/program/education",
        component: () => import(/* webpackChunkName: "center" */ "../pages/client/sub/program/education.vue"),
        props: true,
    },
    {
        // 포토 갤러리
        path: "/program/gallery",
        component: () => import(/* webpackChunkName: "center" */ "../pages/client/sub/program/gallery.vue"),
        props: true,
    },
    {
        // 포토 갤러리
        path: "/program/gallery/:_gallery",
        component: () => import(/* webpackChunkName: "center" */ "../pages/client/sub/program/gallery-view.vue"),
        props: true,
    },

    // 소장품
    {
        // 주요 소장품 안내
        path: "/collection/major",
        component: () => import(/* webpackChunkName: "center" */ "../pages/client/sub/collection/major.vue"),
        props: true,
    },
    {
        // 열람·복제 안내
        path: "/collection/reading",
        component: () => import(/* webpackChunkName: "center" */ "../pages/client/sub/collection/reading.vue"),
        props: true,
    },
    {
        // 기증 · 기탁 안내
        path: "/collection/donation",
        component: () => import(/* webpackChunkName: "center" */ "../pages/client/sub/collection/donation.vue"),
        props: true,
    },

    // 소개
    {
        // 역사관 소개
        path: "/introduction/introduction",
        component: () => import(/* webpackChunkName: "center" */ "../pages/client/sub/introduction/introduction.vue"),
        props: true,
    },
    {
        // 공지사항
        path: "/introduction/notice",
        component: () => import(/* webpackChunkName: "center" */ "../pages/client/sub/introduction/notice.vue"),
        props: true,
    },
    {
        // 공지사항
        path: "/introduction/notice/:_notice",
        component: () => import(/* webpackChunkName: "center" */ "../pages/client/sub/introduction/notice.vue"),
        props: true,
    },
    {
        // 언론보도
        path: "/introduction/coverage",
        component: () => import(/* webpackChunkName: "center" */ "../pages/client/sub/introduction/coverage.vue"),
        props: true,
    },
    {
        // 언론보도
        path: "/introduction/coverage/:_coverage",
        component: () => import(/* webpackChunkName: "center" */ "../pages/client/sub/introduction/coverage-view.vue"),
        props: true,
    },
    {
        // 도서
        path: "/introduction/book",
        component: () => import(/* webpackChunkName: "center" */ "../pages/client/sub/introduction/book.vue"),
        props: true,
    },
    
    // 주요 소장품 상세 페이지
    {
        // 1. 마을금고 운영대장
        path: "/collection/major/1",
        component: () => import(/* webpackChunkName: "center" */ "../pages/client/sub/collection/major/collection01.vue"),
        props: true,
    },
    {
        // 2. 연합회 회원가입증
        path: "/collection/major/2",
        component: () => import(/* webpackChunkName: "center" */ "../pages/client/sub/collection/major/collection02.vue"),
        props: true,
    },
    {
        // 3. 정관
        path: "/collection/major/3",
        component: () => import(/* webpackChunkName: "center" */ "../pages/client/sub/collection/major/collection03.vue"),
        props: true,
    },
    {
        // 4. 마을금고 등록증
        path: "/collection/major/4",
        component: () => import(/* webpackChunkName: "center" */ "../pages/client/sub/collection/major/collection04.vue"),
        props: true,
    },
    {
        // 5. 마을금고 규정집
        path: "/collection/major/5",
        component: () => import(/* webpackChunkName: "center" */ "../pages/client/sub/collection/major/collection05.vue"),
        props: true,
    },
    {
        // 6. 마을금고 기본교재
        path: "/collection/major/6",
        component: () => import(/* webpackChunkName: "center" */ "../pages/client/sub/collection/major/collection06.vue"),
        props: true,
    },
    {
        // 7. 마을금고 관리요원반 교재
        path: "/collection/major/7",
        component: () => import(/* webpackChunkName: "center" */ "../pages/client/sub/collection/major/collection07.vue"),
        props: true,
    },
    {
        // 8. 마을금고 관리요원반 교재
        path: "/collection/major/8",
        component: () => import(/* webpackChunkName: "center" */ "../pages/client/sub/collection/major/collection08.vue"),
        props: true,
    },
    {
        // 9. 안전기금 가입증서
        path: "/collection/major/9",
        component: () => import(/* webpackChunkName: "center" */ "../pages/client/sub/collection/major/collection09.vue"),
        props: true,
    },
    {
        // 10. 회계기(NCR社)
        path: "/collection/major/10",
        component: () => import(/* webpackChunkName: "center" */ "../pages/client/sub/collection/major/collection10.vue"),
        props: true,
    },
    {
        // 11. 자기앞수표 발행기념 순금수표
        path: "/collection/major/11",
        component: () => import(/* webpackChunkName: "center" */ "../pages/client/sub/collection/major/collection11.vue"),
        props: true,
    },
    {
        // 12. 1960년대 통장
        path: "/collection/major/12",
        component: () => import(/* webpackChunkName: "center" */ "../pages/client/sub/collection/major/collection12.vue"),
        props: true,
    },
    {
        // 13. 마을금고 운영수기집
        path: "/collection/major/13",
        component: () => import(/* webpackChunkName: "center" */ "../pages/client/sub/collection/major/collection13.vue"),
        props: true,
    },
    {
        // 14. 월간 새마을금고 창간호
        path: "/collection/major/14",
        component: () => import(/* webpackChunkName: "center" */ "../pages/client/sub/collection/major/collection14.vue"),
        props: true,
    },
    {
        // 15. 사랑의 좀도리운동 캠페인 저금통
        path: "/collection/major/15",
        component: () => import(/* webpackChunkName: "center" */ "../pages/client/sub/collection/major/collection15.vue"),
        props: true,
    },
    {
        // 16. 저축 3조원 달성 포스터
        path: "/collection/major/16",
        component: () => import(/* webpackChunkName: "center" */ "../pages/client/sub/collection/major/collection16.vue"),
        props: true,
    },
    // {
    //     // 공지사항
    //     path: "/center/notice/:_notification",
    //     component: () => import(/* webpackChunkName: "center" */ "../pages/client/center/notice.vue"),
    //     props: true,
    // },

    // // 폼
    // {
    //     // 문의 및 제휴
    //     path: "/inquiry",
    //     component: () => import(/* webpackChunkName: "form" */ "../pages/client/form/Inquiry.vue"),
    //     props: true,
    // },

    // // 개인정보처리방침
    // {
    //     // 개인정보처리방침
    //     path: "/privacy-policy",
    //     component: () => import(/* webpackChunkName: "sub" */ "../pages/client/center/PrivacyPolicyPage.vue"),
    //     props: true,
    // },

    // kcp
    // {
    //     path: "/common/kcp/cert/request",
    //     component: () => import("../pages/common/kcp/KCPCertRequest.vue"),
    //     props: true,
    // },
    // {
    //     path: "/common/kcp/cert/response",
    //     component: () => import("../pages/common/kcp/KCPCertResponse.vue"),
    //     props: true,
    // },

    // 로그인/회원가입
    // {
    //     // 로그인
    //     path: "/login",
    //     component: () => import(/* webpackChunkName: "login" */ "../pages/client/login/main.vue"),
    //     props: true,
    // },
    // {
    //     // 비밀번호 찾기
    //     path: "/login/find-password",
    //     component: () => import(/* webpackChunkName: "login" */ "../pages/client/login/find-password.vue"),
    //     props: true,
    // },
    // {
    //     // 아이디 찾기
    //     path: "/login/find-id",
    //     component: () => import(/* webpackChunkName: "login" */ "../pages/client/login/find-id.vue"),
    //     props: true,
    // },
    // {
    //     // 회원가입
    //     path: "/join",
    //     component: () => import(/* webpackChunkName: "join" */ "../pages/client/join/main.vue"),
    //     props: true,
    // },
    // {
    //     // 회원가입 폼
    //     path: "/join/type",
    //     component: () => import(/* webpackChunkName: "join" */ "../pages/client/join/type.vue"),
    //     props: true,
    // },
    // {
    //     // 회원가입 폼
    //     path: "/join/form",
    //     component: () => import(/* webpackChunkName: "join" */ "../pages/client/join/form.vue"),
    //     props: true,
    // },
    // {
    //     // 회원가입 완료
    //     path: "/join/complete",
    //     component: () => import(/* webpackChunkName: "join" */ "../pages/client/join/complete.vue"),
    //     props: true,
    // },

    // 마이페이지
    // {
    //     // 1:1 문의
    //     path: "/mypage/question",
    //     component: () => import(/* webpackChunkName: "question" */ "../pages/client/mypage/question.vue"),
    //     children: [...QuestionRoutes],
    //     props: true,
    // },
    // {
    //     // 회원 정보 상세
    //     path: "/mypage/myinfo",
    //     component: () => import(/* webpackChunkName: "mypage" */ "../pages/client/mypage/myinfo.vue"),
    //     props: true,
    // },
    // {
    //     // 회원 정보 변경
    //     path: "/mypage/myinfo/:tab",
    //     component: () => import(/* webpackChunkName: "mypage" */ "../pages/client/mypage/myinfo.vue"),
    //     props: true,
    // },
    // {
    //     // 회원 탈퇴
    //     path: "/mypage/leave",
    //     component: () => import(/* webpackChunkName: "mypage" */ "../pages/client/mypage/leave.vue"),
    //     props: true,
    // },
    // {
    //     // 회원 탈퇴
    //     path: "/mypage/leave/:tab",
    //     component: () => import(/* webpackChunkName: "mypage" */ "../pages/client/mypage/leave.vue"),
    //     props: true,
    // },

    ///////////////////////////////////////////////////////////////////////////////////////////////////////////////////
    // 관리자 페이지 라우트
    ///////////////////////////////////////////////////////////////////////////////////////////////////////////////////
    {
        // 관리자 메인
        path: "/console",
        component: () => import(/* webpackChunkName: "console-app" */ "../pages/console/Main.vue"),
        props: true,
        scope: ["console", "notice", "introduction", "photo", "popup"],
    },
    {
        // 관리자 로그 확인
        path: "/console/logs",
        component: () => import(/* webpackChunkName: "console-app" */ "../pages/console/admin/AdminLogs.vue"),
        props: true,
        scope: ["console"],
    },
    {
        // 관리자 공지사항
        path: "/console/notice",
        component: () => import(/* webpackChunkName: "console-app" */ "../pages/console/center/Notification.vue"),
        props: true,
        scope: ["console", "notice"],
    },
    {
        // 관리자 - 게시판관리
        path: "/console/boards",
        component: () => import(/* webpackChunkName: "console-app" */ "../pages/console/board/BoardList.vue"),
        scope: ["console", "photo"],
    },
    {
        // 관리자 - 게시판관리
        path: "/console/boards/create",
        component: () => import(/* webpackChunkName: "console-app" */ "../pages/console/board/BoardView.vue"),
        scope: ["console", "photo"],
    },
    {
        // 관리자 - 게시판관리
        path: "/console/boards/:_board",
        component: () => import(/* webpackChunkName: "console-app" */ "../pages/console/board/BoardView.vue"),
        scope: ["console", "photo"],
    },
    {
        // 관리자 - 게시판관리
        path: "/console/introduction",
        component: () => import(/* webpackChunkName: "console-app" */ "../pages/console/introduction/BoardList.vue"),
        scope: ["console", "introduction"],
    },
    {
        // 관리자 - 게시판관리
        path: "/console/introduction/create",
        component: () => import(/* webpackChunkName: "console-app" */ "../pages/console/introduction/BoardView.vue"),
        scope: ["console", "introduction"],
    },
    {
        // 관리자 - 게시판관리
        path: "/console/introduction/:_board",
        component: () => import(/* webpackChunkName: "console-app" */ "../pages/console/introduction/BoardView.vue"),
        scope: ["console", "introduction"],
    },
    {
        // 관리자 - 회원등급
        path: "/console/users/levels",
        component: () => import(/* webpackChunkName: "console-app" */ "../pages/console/user/LevelList.vue"),
        props: true,
    },
    {
        // 관리자 - 회원등급
        path: "/console/users/withdrawals",
        component: () => import(/* webpackChunkName: "console-app" */ "../pages/console/user/WithdrawalList.vue"),
        props: true,
    },
    {
        // 관리자 - 사용자
        path: "/console/admins",
        component: () => import(/* webpackChunkName: "console-app" */ "../pages/console/admin/AdminList.vue"),
        props: true,
    },
    {
        // 관리자 - 사용자
        path: "/console/admins/create",
        component: () => import(/* webpackChunkName: "console-app" */ "../pages/console/admin/AdminView.vue"),
        props: true,
    },
    {
        // 관리자 - 사용자
        path: "/console/admins/:_admin",
        component: () => import(/* webpackChunkName: "console-app" */ "../pages/console/admin/AdminView.vue"),
        props: true,
    },
    {
        path: "/console/adminsIp",
        component: () => import(/* webpackChunkName: "console-app" */ "../pages/console/admin/AdminIpList.vue"),
        props: true,
        scope: ["console", "root"],
    },
    {
        path: "/console/adminsIp/create",
        component: () => import(/* webpackChunkName: "console-app" */ "../pages/console/admin/AdminIpView.vue"),
        props: true,
    },
    {
        path: "/console/adminsIp/:_ip",
        component: () => import(/* webpackChunkName: "console-app" */ "../pages/console/admin/AdminIpView.vue"),
        props: true,
    },
    {
        // 관리자 - 공지사항
        path: "/console/center/notifications",
        component: () => import(/* webpackChunkName: "console-app" */ "../pages/console/center/Notification.vue"),
        props: true,
    },
    {
        // 관리자 - FAQ
        path: "/console/center/faqs",
        component: () => import(/* webpackChunkName: "console-app" */ "../pages/console/center/Faq.vue"),
        props: true,
    },
    {
        // 관리자 - 1:1문의
        path: "/console/center/questions",
        component: () => import(/* webpackChunkName: "console-app" */ "../pages/console/center/Question.vue"),
        props: true,
    },
    {
        // 관리자 - 서비스 이용약관
        path: "/console/terms",
        component: () => import(/* webpackChunkName: "console-app" */ "../pages/console/terms/TermsList.vue"),
        props: true,
    },
    {
        // 관리자 - 배너
        path: "/console/banners",
        component: () => import(/* webpackChunkName: "console-app" */ "../pages/console/banner/BannerList.vue"),
        props: true,
    },
    {
        // 관리자 - 배너
        path: "/console/banners/create",
        component: () => import(/* webpackChunkName: "console-app" */ "../pages/console/banner/BannerView.vue"),
        props: true,
    },
    {
        // 관리자 - 배너
        path: "/console/banners/:_banner",
        component: () => import(/* webpackChunkName: "console-app" */ "../pages/console/banner/BannerView.vue"),
        props: true,
    },
    {
        // 관리자 - 팝업
        path: "/console/popups",
        component: () => import(/* webpackChunkName: "console-app" */ "../pages/console/popup/PopupList.vue"),
        props: true,
    },
    {
        // 관리자 - 설정
        path: "/console/setting",
        component: () => import(/* webpackChunkName: "console-app" */ "../pages/console/setting/SettingView.vue"),
        props: true,
    },
    {
        // 관리자 - 비밀번호
        path: "/console/password",
        component: () => import(/* webpackChunkName: "console-app" */ "../pages/console/setting/PasswordView.vue"),
        props: true,
    },
];

const router = new VueRouter({
    mode: "history",
    base: process.env.BASE_URL,
    routes,

    scrollBehavior: (to, from, savedPosition) => {
        let scrollTo = undefined;

        if (to.hash) {
            scrollTo = to.hash;
        } else if (savedPosition) {
            scrollTo = savedPosition.y;
        }

        if (scrollTo != undefined)
            return {
                selector: scrollTo,
                behavior: "smooth",
            };
    },
});

router.beforeEach((to, from, next) => {
    try {
        const accessToken = sessionStorage.getItem("accessToken");
        axios.defaults.headers.common["Authorization"] = !!accessToken ? `Bearer ${accessToken}` : "";
        axios.defaults.headers.common["Accept-Language"] = i18n.locale;

        const route = routes.find((route) => route.path == to.path);
        if (!route?.scope) next();
        else {
            if (!accessToken) {
                if (0 <= to.path.indexOf("console")) {
                    next();
                    return;
                } else throw new Error("로그인 후 이용가능합니다");
            }

            const payload = JSON.parse(atob(accessToken.split(".")[1]));
            const scope = payload?.scope || [];

            if (!scope.find((scope) => route.scope.includes(scope))) {
                throw new Error("접근권한이 없습니다");
            }

            next();
        }
    } catch (error) {
        alert(error.message);
        window.location.href = routes.find((route) => route.path == from.path)?.path ?? routes.find((route) => route.path == "/")?.path ?? "about:blank";
    }
});

export default router;
