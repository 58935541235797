var render = function render(){
  var _vm=this;
  var _h=_vm.$createElement;
  var _c=_vm._self._c||_h;

  return _c('router-link', {
    staticClass: "d-flex justify-space-between px-24 py-40",
    attrs: {
      "to": _vm.to
    }
  }, [_c('h3', {
    staticClass: "tit tit--sm"
  }, [_vm._v(_vm._s(_vm.tit))]), _c('icon-arrow-primary', {
    attrs: {
      "direction": "right"
    }
  })], 1);

}
var staticRenderFns = []

export { render, staticRenderFns }