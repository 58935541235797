var render = function render(){
  var _vm$notification;
  var _vm=this;
  var _h=_vm.$createElement;
  var _c=_vm._self._c||_h;

  return _c('router-link', {
    staticClass: "notice-link",
    attrs: {
      "to": `/introduction/notice/${_vm.notification._id}`
    }
  }, [_c('div', {
    staticClass: "tit-wrap tit-wrap--xs"
  }, [_c('h3', {
    staticClass: "tit tit--sm font-weight-medium text-truncate"
  }, [_vm._v(" " + _vm._s(_vm.notification.subject) + " ")])]), _c('p', {
    staticClass: "page-text page-text--xs grey--text"
  }, [_vm._v(" " + _vm._s(_vm.$dayjs((_vm$notification = _vm.notification) === null || _vm$notification === void 0 ? void 0 : _vm$notification.createdAt).format("YYYY-MM-DD")) + " ")])]);

}
var staticRenderFns = []

export { render, staticRenderFns }