<template>
    <client-page class="app--main">
        <main-visual />

        <main-section class="main-section--s-guidance pa-0">
            <div class="h-240px h-lg-120px d-flex align-center">
                <v-row>
                    <v-col cols="6" md="">
                        <v-row class="row--lg">
                            <v-col cols="12" lg="auto">
                                <div class="tit-wrap tit-wrap--xs">
                                    <h3 class="tit tit--sm">관람시간</h3>
                                </div>
                                <p class="page-text page-text--sm">월요일 ~ 토요일 : 오전 10시 ~ 오후 6시 <span class="grey--text">(입장 마감 : 오후 5시)</span></p>
                            </v-col>
                            <v-col cols="12" lg="auto">
                                <div class="tit-wrap tit-wrap--xs">
                                    <h3 class="tit tit--sm">휴관일</h3>
                                </div>
                                <p class="page-text page-text--sm">매주 일요일, 법정 공휴일, 근로자의 날</p>
                            </v-col>
                        </v-row>
                    </v-col>
                </v-row>
                <router-link to="/information/information#group" class="group-link d-flex justify-center align-center h-100 px-20 px-md-40 pr-lg-0">
                    <h3 class="tit tit--sm mr-12 mr-md-24 white--text">단체관람 안내</h3>
                    <icon-arrow-primary color="#fff" direction="right" />
                </router-link>
            </div>
        </main-section>

        <main-section class="main-section--guidance">
            <div class="tit-wrap text-center">
                <h2 class="tit tit--lg">역사관 이용안내</h2>
            </div>
            <v-row>
                <v-col cols="6" md="">
                    <main-circle-card to="/information/information" tit="관람안내" icon="/images/main/circle-card-01.png" color="secondary" class="main-circle-card--1" />
                </v-col>
                <v-col cols="6" md="">
                    <main-circle-card to="/program/education" tit="교육 안내" icon="/images/main/circle-card-02.png" color="#009edb" class="main-circle-card--2" />
                </v-col>
                <v-col cols="6" md="">
                    <main-circle-card to="/information/facility" tit="시설 안내" icon="/images/main/circle-card-03.png" color="primary" class="main-circle-card--3" />
                </v-col>
                <v-col cols="6" md="">
                    <main-circle-card to="/information/directions" tit="오시는 길" icon="/images/main/circle-card-04.png" color="#0b386a" class="main-circle-card--4" />
                </v-col>
            </v-row>
        </main-section>

        <section class="main-section main-section--exhibition">
            <v-container>
                <div class="tit-wrap">
                    <h2 class="tit tit--lg white--text">전시안내</h2>
                </div>
                <v-row no-gutters>
                    <v-col cols="12" sm="5">
                        <ul class="main-section--exhibition__list">
                            <li>
                                <router-link to="/exhibition/hall/1">
                                    <h3 class="tit tit--sm">상설전시</h3>
                                    <icon-arrow-primary direction="right" />
                                    <v-img class="hall-bg" src="/images/main/exhibition-01.jpg" alt="" />
                                </router-link>
                            </li>
                            <li>
                                <router-link to="/exhibition/special">
                                    <h3 class="tit tit--sm">특별전시</h3>
                                    <icon-arrow-primary direction="right" />
                                    <v-img class="hall-bg" src="/images/main/exhibition-02.jpg" alt="" />
                                </router-link>
                            </li>
                        </ul>
                    </v-col>
                </v-row>
            </v-container>
        </section>

        <main-section>
            <v-row>
                <v-col cols="12" lg="auto">
                    <v-row class="row--sm flex-md-column">
                        <v-col cols="12" md="auto">
                            <v-card flat class="notice-card notice-card--intro">
                                <router-link to="/introduction/introduction">
                                    <h3 class="tit tit--sm white--text">역사관 소개</h3>
                                </router-link>
                            </v-card>
                        </v-col>
                        <v-col cols="12" md="auto">
                            <v-card flat tile outlined color="#009EDB" :hover="true" class="notice-card notice-card--donation border-2">
                                <router-link to="/collection/donation">
                                    <h3 class="tit tit--sm color-009EDB">기증 · 기탁 안내</h3>
                                </router-link>
                            </v-card>
                        </v-col>
                    </v-row>
                </v-col>
                
                <v-col cols="12" lg="">
                    <div class="tit-wrap tit-wrap--xs d-flex justify-space-between align-center">
                        <h2 class="tit tit--lg">공지사항</h2>
                        <router-link to="/introduction/notice" class="d-flex">
                            <span class="page-text mr-4">자세히보기</span>
                            <icon-arrow-tertiary direction="right" />
                        </router-link>
                    </div>
                    <v-divider class="pb-20 pb-md-30 pb-lg-40"></v-divider>
                    <v-row class="row--xs">
                        <v-col cols="12" v-for="(notification, index) in notifications" :key="index">
                            <main-notice-link :notification="notification" />
                        </v-col>
                    </v-row>
                </v-col>
            </v-row>
        </main-section>
        <popup-layer></popup-layer>
    </client-page>
</template>

<script>
import api from "@/api";
import ClientPage from "@/sets/styles/apps/client-page.vue";
import MainSection from "@/sets/styles/mains/main-section.vue";
import PopupLayer from "@/components/client/popup/popup-layer.vue";
import MainVisual from "@/components/client/main/main-visual.vue";
import BtnPrimary from "@/components/publish/parents/buttons/btn-primary.vue";
import MainCircleCard from "@/components/client/main/main-circle-card.vue";
import MainExhibitionLink from "@/components/client/main/main-exhibition-link.vue";
import MainNoticeLink from "@/components/client/main/main-notice-link.vue";

import IconArrowPrimary from "@/components/publish/parents/icons/icon-arrow-primary.vue";
import IconArrowSecondary from "@/components/publish/parents/icons/icon-arrow-secondary.vue";
import IconArrowTertiary from "@/components/publish/parents/icons/icon-arrow-tertiary.vue";
import IconArrowQuarternary from "@/components/publish/parents/icons/icon-arrow-quarternary.vue";

export default {
    components: {
        ClientPage,
        PopupLayer,
        MainVisual,
        MainSection,
        BtnPrimary,
        IconArrowPrimary,
        IconArrowSecondary,
        IconArrowTertiary,
        IconArrowQuarternary,
        MainCircleCard,
        MainExhibitionLink,
        MainNoticeLink,
    },
    props: {},
    data() {
        return {
            notifications: [],
        };
    },
    mounted() {
        this.init();
    },
    methods: {
        async init() {
            var { notifications } = await api.v1.center.notifications.gets({
                headers: {
                    skip: (this.page - 1) * this.limit,
                    limit: 3,
                },
                params: this.filter,
            });

            this.notifications = notifications;
        },
    },
};
</script>

<style lang="scss" scoped></style>
