var render = function render(){
  var _vm=this;
  var _h=_vm.$createElement;
  var _c=_vm._self._c||_h;

  return _c('div', {
    staticClass: "main-visual"
  }, [_c('v-img', {
    staticClass: "main-visual__img",
    attrs: {
      "eager": "",
      "src": "/images/main/main-visual.jpg",
      "position": "22% 0",
      "alt": "MG새마을금고역사관 전경"
    }
  }), _c('div', {
    staticClass: "scrolldown scrolldown--double-arrow scrolldown--light"
  }, [_c('div', {
    staticClass: "scrolldown__arrow"
  }, [_c('icon-chevron', {
    attrs: {
      "size": "small",
      "direction": "down",
      "color": "white"
    }
  }), _c('icon-chevron', {
    attrs: {
      "size": "small",
      "direction": "down",
      "color": "white"
    }
  })], 1), _c('div', {
    staticClass: "scrolldown__txt"
  }, [_vm._v("Scroll Down")])])], 1);

}
var staticRenderFns = []

export { render, staticRenderFns }