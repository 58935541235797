<template>
    <header class="header header--fixed header--menu-all" v-scroll="fixedScroll">
        <!-- S: head -->
        <!-- <div class="header__head">
            <v-container>
                <v-row no-gutters>
                    <v-spacer />
                    <v-col cols="auto">
                        <user-menu />
                    </v-col>
                </v-row>
            </v-container>
        </div> -->
        <!-- E: head -->
        <!-- S: body -->
        <div class="header__body">
            <v-container>
                <h1 class="header__logo">
                    <a href="/" title="홈으로 이동"><span class="d-sr-only">MG새마을금고역사관</span></a>
                </h1>
                <div class="header__contents">
                    <div class="header__contents-wrap">
                        <div class="header__gnb d-block d-xl-flex justify-center">
                            <!-- S: Gnb -->
                            <client-gnb className="gnb" />
                            <!-- E: Gnb -->
                        </div>
                    </div>
                    <div class="header__bg" />
                </div>
                <div class="header__right">
                    <router-link class="e-history-link" to="/res/e-history/" target="_blank">60주년 e-역사관</router-link>
                    <btn-hamburger-primary class="btn-hamburger--pc" />
                    <!-- <v-row align="center">
                        <v-col cols="auto">
                            <div class="lang-wrap">
                                <v-btn icon text><v-icon>mdi-earth</v-icon></v-btn>
                                <ul class="lang">
                                    <li class="on">KOR</li>
                                    <li><router-link to="/en">ENG</router-link></li>
                                </ul>
                            </div>
                        </v-col>
                    </v-row> -->
                </div>
            </v-container>
        </div>
        <!-- E: body -->
        <btn-hamburger-primary class="btn-hamburger--mobile" />

        <!-- S: Side gnb -->
        <div class="side-gnb-wrap">
            <v-container>
                <client-gnb className="slide-gnb"></client-gnb>
            </v-container>
        </div>
        <!-- E: Side gnb -->
    </header>
</template>

<script>
import ClientGnb from "@/components/client/inc/client-gnb.vue";
import LocaleSelect from "@/components/client/inc/locale-select.vue";
import MainHeaderSearch from "./main-header-search.vue";
import BtnHamburgerPrimary from "@/components/publish/parents/buttons/btn-hamburger-primary.vue";

export default {
    components: {
        ClientGnb,
        LocaleSelect,
        MainHeaderSearch,
        BtnHamburgerPrimary,
    },
    data() {
        return {
            window: {
                width: 0,
                height: 0,
            },
            dialog: false,
        };
    },
    mounted() {
        window.scrollTo(0, 0); // 라우터 이동 시 스크롤 상단부터 시작

        // 전체메뉴 초기화
        pcMenuOut();

        if (this.isDesktop) {
            this.initPcEvent();
        }
        if (this.isMobile) {
            this.initMobileEvent();
        }
    },
    methods: {
        fixedScroll: function () {
            var header = document.querySelector(".header");
            var scrollTop = window.scrollY || document.documentElement.scrollTop;

            if (scrollTop > 50) {
                header.classList.add("scroll-active");
            } else {
                header.classList.remove("scroll-active");
            }
        },
        handleResize: function () {
            this.window.width = window.innerWidth;
            this.window.height = window.innerHeight;
        },
        // PC
        initPcEvent: function () {
            // gnb
            var lis = document.getElementsByClassName("gnb__li");
            for (var i = 0; i < lis.length; i++) {
                lis[i].addEventListener("mouseenter", gnbLiOver);
                lis[i].addEventListener("mouseleave", gnbLiOut);
            }

            var gnb = document.querySelector(".gnb");
            gnb.addEventListener("mouseenter", gnbOver);
            gnb.addEventListener("mouseleave", gnbOut);

            // lang
            // var lang = document.querySelector(".lang-wrap");
            // lang.addEventListener("click", langBox);

            // hamburgerBtnPc
            var hamburgerBtnPc = document.querySelector(".btn-hamburger--pc");
            hamburgerBtnPc.addEventListener("click", pcMenuToggle);

            var hiddenBtnPc = document.querySelector(".hidden-btn");
            hiddenBtnPc.addEventListener("blur", pcMenuOut);
            hiddenBtnPc.addEventListener("keypress", pcMenuOut);
        },
        destroyPcEvent: function () {
            gnb;
            var lis = document.getElementsByClassName("gnb__li");
            for (var i = 0; i < lis.length; i++) {
                lis[i].removeEventListener("mouseenter", gnbLiOver);
                lis[i].removeEventListener("mouseleave", gnbLiOut);
            }

            var gnb = document.querySelector(".gnb");
            gnb.removeEventListener("mouseenter", gnbOver);
            gnb.removeEventListener("mouseleave", gnbOut);

            // lang
            // var lang = document.querySelector(".lang-wrap");
            // lang.removeEventListener("click", langBox);
            // lang.classList.remove("active");

            // hamburgerBtnPc
            var hamburgerBtnPc = document.querySelector(".btn-hamburger--pc");
            hamburgerBtnPc.removeEventListener("click", pcMenuToggle);

            var hiddenBtnPc = document.querySelector(".hidden-btn");
            hiddenBtnPc.removeEventListener("blur", pcMenuOut);
            hiddenBtnPc.removeEventListener("keypress", pcMenuOut);
        },
        // Mobile
        initMobileEvent: function () {
            // hamburgerBtn
            var hamburgerBtnMobile = document.querySelector(".btn-hamburger--mobile");
            hamburgerBtnMobile.addEventListener("click", mobileMenuToggle);

            // gnb
            var gnbLink = document.querySelectorAll(".gnb__link:not(.gnb__link--toggle)");
            var gnbLinkToggle = document.querySelectorAll(".gnb__link--toggle");
            var gnbSubLink = document.querySelectorAll(".gnb__sub >li >a");

            gnbLinkToggle.forEach(function (el) {
                el.addEventListener("click", toggleAccordion);
            });
            gnbLink.forEach(function (el) {
                el.addEventListener("click", hideGnb);
            });
            gnbSubLink.forEach(function (el) {
                el.addEventListener("click", hideGnb);
            });
        },
        destroyMobileEvent: function () {
            // hamburgerBtn
            var hamburgerBtnMobile = document.querySelector(".btn-hamburger--mobile");
            hamburgerBtnMobile.removeEventListener("click", mobileMenuToggle);

            // gnb
            var gnbLink = document.querySelectorAll(".gnb__link:not(.gnb__link--toggle)");
            var gnbLinkToggle = document.querySelectorAll(".gnb__link--toggle");
            var gnbSubLink = document.querySelectorAll(".gnb__sub >li >a");

            gnbLinkToggle.forEach(function (el) {
                el.removeEventListener("click", toggleAccordion);
            });
            gnbLink.forEach(function (el) {
                el.removeEventListener("click", hideGnb);
            });
            gnbSubLink.forEach(function (el) {
                el.removeEventListener("click", hideGnb);
            });
        },
    },
    computed: {
        isDesktop: function () {
            return this.window.width >= 1200;
        },
        isMobile: function () {
            return this.window.width < 1200;
        },
    },
    created() {
        window.addEventListener("resize", this.handleResize);
        this.handleResize();
    },
    destroyed() {
        window.removeEventListener("resize", this.handleResize);
        if (this.isDesktop) {
            this.destroyPcEvent();
        }
        if (this.isMobile) {
            thid.destroyMobileEvent();
        }
    },
    watch: {
        isDesktop: function () {
            if (this.isDesktop) {
                this.initPcEvent();
            } else {
                this.destroyPcEvent();
            }
        },
        isMobile: function () {
            if (this.isMobile) {
                this.initMobileEvent();
            } else {
                this.destroyMobileEvent();
            }
        },
    },
};

// PC
function gnbLiOver() {
    var headerBg = document.querySelector(".header__bg");
    var gnbSub = this.querySelector(".gnb__sub");

    this.classList.add("active");
    if (gnbSub == null) {
        headerBg.classList.add("none");
    }
}
function gnbLiOut() {
    var headerBg = document.querySelector(".header__bg");
    var gnbSub = this.querySelector(".gnb__sub");

    this.classList.remove("active");
    if (gnbSub == null) {
        headerBg.classList.remove("none");
    }
}
function gnbOver() {
    var header = document.querySelector(".header");
    header.classList.add("menu-active");
}
function gnbOut() {
    var header = document.querySelector(".header");
    header.classList.remove("menu-active");
}
// function langBox() {
//     var lang = document.querySelector(".lang-wrap");
//     lang.classList.toggle("active");
// }

function pcMenuToggle() {
    var HTML = document.querySelector("html");
    var header = document.querySelector(".header");
    var hamburgerBtnPc = document.querySelector(".btn-hamburger--pc");

    header.classList.toggle("pc-menu-on");
    HTML.classList.toggle("pc-menu-hidden");
    hamburgerBtnPc.classList.toggle("active");
}
// function pcMenuOver() {
//     var HTML = document.querySelector("html");
//     var header = document.querySelector(".header");
//     var hamburgerBtnPc = document.querySelector(".btn-hamburger--pc");

//     header.classList.add("pc-menu-on");
//     HTML.classList.add("pc-menu-hidden");
//     hamburgerBtnPc.classList.add("active");
// }
function pcMenuOut() {
    var HTML = document.querySelector("html");
    var header = document.querySelector(".header");
    var hamburgerBtnPc = document.querySelector(".btn-hamburger--pc");

    header.classList.remove("pc-menu-on");
    HTML.classList.remove("pc-menu-hidden");
    hamburgerBtnPc.classList.remove("active");
}

// Mobile
function mobileMenuToggle() {
    // var HTML = document.querySelector('html');
    var header = document.querySelector(".header");
    var gnbLinkToggle = document.querySelectorAll(".gnb__link--toggle");
    var hamburgerBtnMobile = document.querySelector(".btn-hamburger--mobile");

    header.classList.toggle("mobile-menu-on");
    hamburgerBtnMobile.classList.toggle("active");
    // HTML.classList.toggle('hidden');

    if (header.classList.contains("mobile-menu-on")) {
        gnbLinkToggle.forEach(function (el) {
            el.parentNode.classList.remove("active");
        });
    }
}
function toggleAccordion(el) {
    var gnbLinkToggle = document.querySelectorAll(".gnb__link--toggle");
    var targetLi = el.currentTarget.parentNode.classList;
    // var target = el.currentTarget;

    el.preventDefault();
    if (targetLi.contains("active")) {
        targetLi.remove("active");
    } else {
        gnbLinkToggle.forEach(function (el) {
            el.parentNode.classList.remove("active");
        });
        targetLi.add("active");
    }
}
function hideGnb() {
    var header = document.querySelector(".header");
    var lis = document.querySelectorAll("gnb__li");
    var gnbLinkToggle = document.querySelectorAll(".gnb__link--toggle");

    header.classList.remove("mobile-menu-on");

    if (lis.classList.contains("active")) {
        lis.forEach(function (el) {
            el.classList.remove("active");
        });
    }
    if (header.classList.contains("mobile-menu-on")) {
        gnbLinkToggle.forEach(function (el) {
            el.parentNode.classList.remove("active");
        });
    }
}
</script>

<style lang="scss" scoped></style>
