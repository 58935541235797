<template>
    <router-link class="notice-link" :to="`/introduction/notice/${notification._id}`">
        <div class="tit-wrap tit-wrap--xs">
            <h3 class="tit tit--sm font-weight-medium text-truncate">
                {{ notification.subject }}
            </h3>
        </div>
        <p class="page-text page-text--xs grey--text">
            {{ $dayjs(notification?.createdAt).format("YYYY-MM-DD") }}
        </p>
    </router-link>
</template>

<script>
export default {
    props: {
        notification: { type: Object, default: null },
        color: { type: String, default: "" },
        to: { type: String, default: "" },
        tit: { type: String, default: "" },
        icon: { type: String, default: "" },
    },
    data: () => {
        return {
        };
    },
};
</script>

<style lang="scss" scoped>
    .notice-link {
        background: var(--v-grey-lighten5);
        display: block;
        width: 100%;
        height: 100%;
        padding: 24px;
    }
</style>